import { cloneDeep, pullAt } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// firebase
import { db, fieldValue, fromDate, functions } from 'config/firebase';
// utils
import {
  addMonths,
  addWeeks,
  addYears,
  format,
  getDate,
  getDay,
  getMonth,
  getYear,
  setDay,
  setMonth,
  startOfMonth,
  subWeeks
} from 'date-fns';
import { formatDate, convertTimestampToTimezone, getUnix } from 'utils/date-utils';

// ----------------------------------------------------------------------

function getEventIndexById(array, filterValue) {
  return array.indexOf(array.filter((item) => item.id === filterValue)[0]);
}

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  events: [],
  calendars: [],
  calendarList: [],
  isOpenModal: true,
  selectedEventId: '',
  selectedRange: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // IS SUCCESS
    isSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // CREATE RECURRING EVENTS SUCCESS
    createRecurEventSuccess(state, action) {
      const newEvents = action.payload;
      newEvents.forEach((event) => {
        event.start = new Date(event.start?._seconds * 1000);
        event.end = new Date(event.end?._seconds * 1000);
      });
      state.isLoading = false;
      state.events = [...state.events, ...newEvents];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },

    // GET CALENDARS
    getCalendarsSuccess(state, action) {
      state.isLoading = false;
      state.calendars = action.payload.calendars;
      state.calendarList = action.payload.calendarList;
    },

    // CREATE CALENDARS
    getCreateCalendarSuccess(state, action) {
      state.isLoading = false;
      state.calendars = [...state.calendars, action.payload];
      state.calendarList = [...state.calendarList, action.payload];
    },

    // UPDATE CALENDARS
    getUpdateCalendarSuccess(state, action) {
      state.isLoading = false;

      const calendarIndex = state.calendars.findIndex((calendar) => calendar.id === action.payload.id);
      state.calendars[calendarIndex] = action.payload;
      const calendarListIndex = state.calendarList.findIndex((calendar) => calendar.id === action.payload.id);
      state.calendarList[calendarListIndex] = action.payload;

      for (const event of state.events) {
        for (const id of action.payload.eventIds) {
          if (event.id === id) {
            event.textColor = action.payload.groupColor;
          }
        }
      }
    },

    // DELETE CALENDARS
    getDeleteCalendarSuccess(state, action) {
      state.isLoading = false;

      state.calendars = state.calendars.filter((calendar) => calendar.id !== action.payload.calendarId);
      state.calendarList = state.calendarList.filter((calendar) => calendar.id !== action.payload.calendarId);

      for (const event of state.events) {
        for (const id of action.payload.eventIds) {
          if (event.id === id) {
            event.calendars[0] = 'unassigned';
            event.textColor = '#E6E6E6';
          }
        }
      }
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent, updateCalendarUI } = slice.actions;

// ----------------------------------------------------------------------

const getSecondWeekDateArray = (start, end, date, startTime, endTime, timeZoneString, recurrenceEndDate) => {
  const recurrenceEndTime = recurrenceEndDate
    ? new Date(`${recurrenceEndDate}T${endTime}:00.000${timeZoneString}`)
    : addMonths(start, 6);
  const selectedDate = new Date(`${date}T${startTime}:00.000${timeZoneString}`);

  const weekday = getDay(selectedDate);

  let secondWeekdayStart = start;
  let secondWeekdayEnd = end;

  const arr = [];
  while (secondWeekdayStart <= recurrenceEndTime && arr.length < 100) {
    if (secondWeekdayStart >= selectedDate) {
      arr.push({
        start: secondWeekdayStart,
        end: secondWeekdayEnd
      });
    }
    const firstOfMonth = format(startOfMonth(addMonths(secondWeekdayStart, 1)), 'yyyy-MM-dd');
    const firstOfMonthStart = new Date(`${firstOfMonth}T${startTime}:00.000${timeZoneString}`);
    const firstOfMonthEnd = new Date(`${firstOfMonth}T${endTime}:00.000${timeZoneString}`);

    secondWeekdayStart = addWeeks(setDay(firstOfMonthStart, weekday, { weekStartsOn: getDay(firstOfMonthStart) }), 1);
    secondWeekdayEnd = addWeeks(setDay(firstOfMonthEnd, weekday, { weekStartsOn: getDay(firstOfMonthEnd) }), 1);
  }
  return arr;
};

// ----------------------------------------------------------------------

const getFirstWeekDateArray = (start, end, date, startTime, endTime, timeZoneString, recurrenceEndDate) => {
  const recurrenceEndTime = recurrenceEndDate
    ? new Date(`${recurrenceEndDate}T${endTime}:00.000${timeZoneString}`)
    : addMonths(start, 6);
  const selectedDate = new Date(`${date}T${startTime}:00.000${timeZoneString}`);

  const weekday = getDay(selectedDate);

  let firstWeekdayStart = start;
  let firstWeekdayEnd = end;

  const arr = [];
  while (firstWeekdayStart <= recurrenceEndTime && recurrenceEndTime && arr.length < 100) {
    if (firstWeekdayStart >= selectedDate) {
      arr.push({
        start: firstWeekdayStart,
        end: firstWeekdayEnd
      });
    }
    const firstOfMonth = format(startOfMonth(addMonths(firstWeekdayStart, 1)), 'yyyy-MM-dd');
    const firstOfMonthStart = new Date(`${firstOfMonth}T${startTime}:00.000${timeZoneString}`);
    const firstOfMonthEnd = new Date(`${firstOfMonth}T${endTime}:00.000${timeZoneString}`);

    firstWeekdayStart = setDay(firstOfMonthStart, weekday, { weekStartsOn: getDay(firstOfMonthStart) });
    firstWeekdayEnd = setDay(firstOfMonthEnd, weekday, { weekStartsOn: getDay(firstOfMonthEnd) });
  }
  return arr;
};

// ----------------------------------------------------------------------

const getFirstTwoWeekDateArray = (
  start,
  end,
  date,
  startTime,
  endTime,
  timeZoneString,
  secondMon,
  recurrenceEndDate
) => {
  const recurrenceEndTime = recurrenceEndDate
    ? new Date(`${recurrenceEndDate}T${endTime}:00.000${timeZoneString}`)
    : addMonths(start, 6);
  const selectedDate = new Date(`${date}T${startTime}:00.000${timeZoneString}`);

  const weekday = getDay(selectedDate);

  let firstWeekdayStart = null;
  let firstWeekdayEnd = null;
  let secondWeekdayStart = null;
  let secondWeekdayEnd = null;

  if (secondMon) {
    secondWeekdayStart = start;
    secondWeekdayEnd = end;

    // second monday of the month
    firstWeekdayStart = subWeeks(secondWeekdayStart, 1);
    firstWeekdayEnd = subWeeks(secondWeekdayEnd, 1);
  } else {
    firstWeekdayStart = start;
    firstWeekdayEnd = end;

    secondWeekdayStart = addWeeks(firstWeekdayStart, 1);
    secondWeekdayEnd = addWeeks(firstWeekdayEnd, 1);
  }

  const arr = [];
  while (firstWeekdayStart <= recurrenceEndTime && secondWeekdayStart <= recurrenceEndTime && arr.length < 100) {
    if (firstWeekdayStart >= selectedDate || secondWeekdayStart >= selectedDate) {
      // adding two mondays at a time here
      if (firstWeekdayStart < selectedDate && secondWeekdayStart >= selectedDate) {
        arr.push(
          // second monday of the month event start and end times
          {
            start: secondWeekdayStart,
            end: secondWeekdayEnd
          }
        );
      } else {
        arr.push(
          // first monday of the month event start and end times
          {
            start: firstWeekdayStart,
            end: firstWeekdayEnd
          },
          // second monday of the month event start and end times
          {
            start: secondWeekdayStart,
            end: secondWeekdayEnd
          }
        );
      }
    }
    const firstOfMonth = format(startOfMonth(addMonths(firstWeekdayStart, 1)), 'yyyy-MM-dd');
    const firstOfMonthStart = new Date(`${firstOfMonth}T${startTime}:00.000${timeZoneString}`);
    const firstOfMonthEnd = new Date(`${firstOfMonth}T${endTime}:00.000${timeZoneString}`);

    firstWeekdayStart = setDay(firstOfMonthStart, weekday, { weekStartsOn: getDay(firstOfMonthStart) });
    firstWeekdayEnd = setDay(firstOfMonthEnd, weekday, { weekStartsOn: getDay(firstOfMonthEnd) });

    secondWeekdayStart = addWeeks(firstWeekdayStart, 1);
    secondWeekdayEnd = addWeeks(firstWeekdayEnd, 1);
  }
  return arr;
};

const getDateArray = (start, end, endTime, recurrence, recurrenceEndDate, timeZoneString) => {
  if (recurrence === 'no-repeat') {
    return;
  }

  let _recurrenceEndDate = null;
  if (recurrence === 'yearly' && !recurrenceEndDate) {
    _recurrenceEndDate = addYears(new Date(), 2);
  } else if (recurrenceEndDate) {
    _recurrenceEndDate = new Date(`${recurrenceEndDate}T${endTime}:00.000${timeZoneString}`);
  }
  // not needed since you're requiring recurrenceEndDate if a recurrence has been selected
  else {
    _recurrenceEndDate = addMonths(new Date(), 6);
  }

  const arr = [];
  let st = new Date(start);
  let et = new Date(end);

  while (st <= _recurrenceEndDate && arr.length < 100) {
    arr.push({
      start: new Date(st),
      end: new Date(et)
    });

    if (recurrence === 'daily') {
      st.setDate(st.getDate() + 1);
      et.setDate(et.getDate() + 1);
    }

    if (recurrence === 'weekly') {
      st.setDate(st.getDate() + 7);
      et.setDate(et.getDate() + 7);
    }

    if (recurrence === 'monthly') {
      st = addMonths(st, 1);
      et = addMonths(et, 1);
    }

    if (recurrence === 'yearly') {
      st = addYears(st, 1);
      et = addYears(et, 1);
    }
  }
  /* eslint-disable-next-line */
  return arr;
};

export function getEvents() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client } = cloneDeep(getState());
    const { currentClient } = client;

    try {
      const eventsCollection = db.collection('clients').doc(currentClient.id).collection('events');
      const getEvents = await eventsCollection.get();
      const events = [];
      getEvents.forEach(async (item) => {
        const event = item.data();
        const { start, end, timeZone } = event;
        const startDate = new Date(start?.seconds * 1000);
        const endDate = new Date(end?.seconds * 1000);

        const eventObject = {
          ...event,
          start: startDate,
          end: endDate,
          editedValues: {
            startTime: convertTimestampToTimezone(getUnix(startDate), timeZone, 'HH:mm'),
            endTime: convertTimestampToTimezone(getUnix(endDate), timeZone, 'HH:mm')
          }
        };

        return events.push(eventObject);
      });

      dispatch(slice.actions.getEventsSuccess(events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client } = cloneDeep(getState());
    const { currentClient } = client;

    // cloud function logic
    try {
      const createEventResponse = await functions.httpsCallable('events-createEvent')({
        newEvent,
        currentClient,
        date: newEvent.date,
        startTime: newEvent.startTime,
        endTime: newEvent.endTime,
        timeZone: newEvent.timeZone,
        recurrence: newEvent.recurrence,
        recurrenceEndDate: newEvent.recurrenceEndDate,
        notification: newEvent.notification
      });

      if (createEventResponse.data.success) {
        dispatch(slice.actions.createRecurEventSuccess(createEventResponse.data.createdEvents));
      } else {
        console.log(createEventResponse.data.message);
        throw new Error(createEventResponse.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(updateEvent, scope) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client, calendar } = cloneDeep(getState());
    const { currentClient } = client;
    const { events } = calendar;
    const eventIndex = events.findIndex((event) => event.id === updateEvent.id);
    const curEvent = events[eventIndex];

    try {
      const collectionRef = db.collection('clients').doc(currentClient.id).collection('events');
      const docRef = collectionRef.doc(updateEvent.id);

      const { date, startTime, endTime, timeZone, recurrence, recurrenceEndDate, notification } = updateEvent;

      let { recurringEventId } = updateEvent;

      const changeFromNoRepeatToRecurr = curEvent.recurrence === 'no-repeat' && updateEvent.recurrence !== 'no-repeat';

      if (typeof recurringEventId === 'undefined' && changeFromNoRepeatToRecurr) {
        recurringEventId = null;
      }

      const timeZoneString = convertTimestampToTimezone(getUnix(new Date()), timeZone, 'ZZ');

      let start = null;
      let end = null;
      let secondMon = false;

      const selectedDate = new Date(`${date}T${startTime}:00.000${timeZoneString}`);

      if (
        recurrence === '1st and 2nd weekday each month' ||
        recurrence === '1st weekday each month' ||
        recurrence === '2nd weekday each month'
      ) {
        const weekday = getDay(selectedDate);

        const firstOfMonth = format(startOfMonth(selectedDate), 'yyyy-MM-dd');
        let firstOfMonthStart = new Date(`${firstOfMonth}T${startTime}:00.000${timeZoneString}`);
        let firstOfMonthEnd = new Date(`${firstOfMonth}T${endTime}:00.000${timeZoneString}`);

        // if the user selects a date earlier in the month for 1st and 2nd weekday recurring, the events start the following month
        const beforeThisMonth = getMonth(firstOfMonthStart) <= getMonth(new Date());
        const thisYear = getYear(firstOfMonthStart) <= getYear(new Date());

        if (beforeThisMonth && thisYear) {
          const curMonth = getMonth(new Date());
          firstOfMonthStart = setMonth(firstOfMonthStart, curMonth);
          firstOfMonthStart = addMonths(firstOfMonthStart, 1);
          firstOfMonthEnd = setMonth(firstOfMonthEnd, curMonth);
          firstOfMonthEnd = addMonths(firstOfMonthEnd, 1);
        }

        start = setDay(firstOfMonthStart, weekday, { weekStartsOn: getDay(firstOfMonthStart) });
        end = setDay(firstOfMonthEnd, weekday, { weekStartsOn: getDay(firstOfMonthEnd) });

        // if the first monday start date is before the selected date, then change the start date to a second monday and change the flag
        if (getDate(start) < 7 && recurrence === '2nd weekday each month') {
          start = addWeeks(start, 1);
          end = addWeeks(end, 1);
          secondMon = true;
        }
      } else {
        start = new Date(`${date}T${startTime}:00.000${timeZoneString}`);
        end = new Date(`${date}T${endTime}:00.000${timeZoneString}`);
      }

      let fbEventObject = null;
      let fbNotificationObject = null;

      const removingNotification = curEvent.notification && !notification;
      const isRecurrParentEvent =
        (updateEvent.recurringEventId === null && updateEvent.recurrence !== 'no-repeat') || changeFromNoRepeatToRecurr;

      // remove notification from firestore and update the event
      if (removingNotification) {
        const notifCollectionRef = db.collection('notifications');
        try {
          await notifCollectionRef
            .where('eventId', '==', updateEvent.id)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const curNotification = doc.data();
                db.doc(`notifications/${curNotification.id}`).delete();
              });
            });

          // update event
          await docRef.update({
            notification: false,
            notifDate: fieldValue.delete(),
            notifTime: fieldValue.delete(),
            notifMessage: fieldValue.delete()
          });
        } catch (error) {
          console.log(error);
        }
      }

      // update the current event's notification, adding one if there wasn't one before
      if (notification) {
        let notificationRef = null;
        const notifCollectionRef = db.collection('notifications');
        try {
          const addingNotification = !curEvent.notification && notification;

          // adding a new notification to the event
          if (addingNotification) {
            notificationRef = db.collection('notifications').doc();
            const { notifDate, notifTime, title, notifMessage, id } = updateEvent;
            const timeZoneString = formatDate(new Date(notifDate), 'ZZ');
            const notificationDate = new Date(`${notifDate}T${notifTime}:00.000${timeZoneString}`);
            fbNotificationObject = {
              status: 'scheduled',
              clientId: currentClient.id,
              id: notificationRef.id,
              message: notifMessage,
              title,
              performAt: fromDate(notificationDate),
              eventId: id
            };
          } else {
            // updating an existing notification
            await notifCollectionRef
              .where('eventId', '==', updateEvent.id)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const curNotification = doc.data();
                  notificationRef = db.doc(`notifications/${curNotification.id}`);
                  const { notifDate, notifTime, title, notifMessage } = updateEvent;
                  const timeZoneString = formatDate(new Date(notifDate), 'ZZ');
                  const newNotifDate = new Date(`${notifDate}T${notifTime}:00.000${timeZoneString}`);
                  const curNotificationDate = new Date(curNotification.performAt.seconds * 1000);

                  fbNotificationObject = {
                    ...curNotification,
                    message: notifMessage,
                    title,
                    performAt: fromDate(newNotifDate),
                    ...(newNotifDate > curNotificationDate && { status: 'scheduled' })
                  };
                });
              });
          }

          // send updated notification to notification collection
          await notificationRef.set(fbNotificationObject, { merge: true });
        } catch (error) {
          console.log(error);
        }
      }

      delete updateEvent.date;
      delete updateEvent.startTime;
      delete updateEvent.endTime;

      fbEventObject = {
        ...updateEvent,
        start: fromDate(start),
        end: fromDate(end),
        editedValues: {
          start: fromDate(start),
          end: fromDate(end),
          startTime,
          endTime
        },
        ...((isRecurrParentEvent || recurrence !== 'no-repeat') && { recurringEventId: null })
      };

      if (changeFromNoRepeatToRecurr) {
        const batch = db.batch();
        scope = null;

        // update original updated event in firestore and add to events array to send to redux state
        await docRef.set(fbEventObject, { merge: true });
        const eventIndex = events.findIndex((event) => event.id === updateEvent.id);
        events[eventIndex] = {
          ...fbEventObject,
          start,
          end
        };

        let _recurrences = [];
        if (recurrence === '1st and 2nd weekday each month') {
          _recurrences = getFirstTwoWeekDateArray(
            start,
            end,
            date,
            startTime,
            endTime,
            timeZoneString,
            secondMon,
            recurrenceEndDate
          );
        } else if (recurrence === '1st weekday each month') {
          _recurrences = getFirstWeekDateArray(start, end, date, startTime, endTime, timeZoneString, recurrenceEndDate);
        } else if (recurrence === '2nd weekday each month') {
          _recurrences = getSecondWeekDateArray(
            start,
            end,
            date,
            startTime,
            endTime,
            timeZoneString,
            recurrenceEndDate
          );
        } else {
          _recurrences = getDateArray(start, end, endTime, recurrence, recurrenceEndDate, timeZoneString);
        }
        _recurrences.shift();
        const recurrenceWrites = _recurrences.map((recurrenceDates) => {
          // eslint-disable-next-line no-unused-vars
          const { notifDate, notifMessage, notifTime, ...newEventWithoutNotif } = fbEventObject;

          return {
            ...newEventWithoutNotif,
            ...recurrenceDates,
            recurringEventId: fbEventObject.id,
            editedValues: {
              ...recurrenceDates
            },
            notification: false
          };
        });

        recurrenceWrites.forEach((doc) => {
          const recurrenceRef = collectionRef.doc();
          batch.set(recurrenceRef, {
            ...doc,
            id: recurrenceRef.id,
            start: fromDate(doc.start),
            end: fromDate(doc.end),
            editedValues: {
              start: fromDate(start),
              end: fromDate(end),
              startTime,
              endTime
            }
          });
          events.push({
            ...doc,
            start: doc.start,
            end: doc.end,
            id: recurrenceRef.id,
            editedValues: {
              start,
              end,
              startTime,
              endTime
            }
          });
        });

        batch.commit();
      }

      if (scope === 'only') {
        // Creates Recurring Events
        // TO DO: Move logic to be triggered and ran in Cloud Function
        await docRef.set(fbEventObject, { merge: true });
        const eventIndex = events.findIndex((event) => event.id === updateEvent.id);
        events[eventIndex] = {
          ...fbEventObject,
          start,
          end
        };
      }

      if (scope === 'future' || scope === 'all') {
        const reduxEventIndex = events.findIndex((event) => event.id === updateEvent.id);

        const batch = db.batch();
        const docsToUpdate = [];

        const collectionGroupRef = db
          .collectionGroup('events')
          .where('recurringEventId', '==', recurringEventId !== null ? recurringEventId : updateEvent.id);
        let parentRecurrEventRef = null;
        if (isRecurrParentEvent) {
          parentRecurrEventRef = collectionRef.doc(updateEvent.id);
        } else if (updateEvent.recurringEventId !== null) {
          const _parentDoc = await collectionRef.doc(recurringEventId).get();
          if (_parentDoc.exists) {
            parentRecurrEventRef = collectionRef.doc(recurringEventId);
          } else {
            parentRecurrEventRef = null;
          }
        }

        // this and future events
        if (scope === 'future') {
          if (parentRecurrEventRef && isRecurrParentEvent) {
            await parentRecurrEventRef.get().then((doc) => docsToUpdate.push(doc.data()));
          }
          const collectionGroup = await collectionGroupRef
            .orderBy('start')
            .startAt(events[reduxEventIndex].start)
            .get();
          collectionGroup.forEach((doc) => docsToUpdate.push(doc.data()));
        }

        // all events
        if (scope === 'all') {
          if (parentRecurrEventRef) {
            await parentRecurrEventRef.get().then((doc) => docsToUpdate.push(doc.data()));
          }

          const collectionGroup = await collectionGroupRef.get();
          collectionGroup.forEach((doc) => {
            docsToUpdate.push(doc.data());
          });
        }

        docsToUpdate.forEach((doc) => {
          const reduxEventIndex = events.findIndex((event) => event.id === doc.id);
          const date = formatDate(new Date(doc.start.seconds * 1000), 'YYYY-MM-DD');
          const timeZoneString = convertTimestampToTimezone(getUnix(new Date(date)), timeZone, 'ZZ');
          const _start = new Date(`${date}T${startTime}:00.000${timeZoneString}`);
          const _end = new Date(`${date}T${endTime}:00.000${timeZoneString}`);

          // update the selected event in firestore and add to events array to send to redux state
          if (doc.id === updateEvent.id) {
            events[reduxEventIndex] = {
              ...fbEventObject,
              id: doc.id,
              recurringEventId: doc.recurringEventId,
              start: _start,
              end: _end
            };
            batch.set(collectionRef.doc(doc.id), {
              ...fbEventObject,
              start: fromDate(_start),
              end: fromDate(_end),
              id: doc.id,
              recurringEventId: doc.recurringEventId
            });
          }
          // if the current document has a notification, update the event's data model in redux state and firestore, notification creation/update has already been handled above
          else if (doc.notification) {
            // eslint-disable-next-line no-unused-vars
            const { notifDate, notifMessage, notifTime, ...newEventWithoutNotif } = fbEventObject;

            events[reduxEventIndex] = {
              ...newEventWithoutNotif,
              id: doc.id,
              recurringEventId: doc.recurringEventId,
              start: _start,
              end: _end,
              notification: doc.notification,
              notifDate: doc.notifDate,
              notifMessage: doc.notifMessage,
              notifTime: doc.notifTime
            };

            batch.set(collectionRef.doc(doc.id), {
              ...newEventWithoutNotif,
              start: fromDate(_start),
              end: fromDate(_end),
              id: doc.id,
              recurringEventId: doc.recurringEventId,
              notification: doc.notification,
              notifDate: doc.notifDate,
              notifMessage: doc.notifMessage,
              notifTime: doc.notifTime
            });
          } else {
            // keep notifications from being added to events that do not have them
            // eslint-disable-next-line no-unused-vars
            const { notifDate, notifMessage, notifTime, notification, ...newEventWithoutNotif } = fbEventObject;

            events[reduxEventIndex] = {
              ...newEventWithoutNotif,
              id: doc.id,
              recurringEventId: doc.recurringEventId,
              start: _start,
              end: _end
            };

            batch.set(collectionRef.doc(doc.id), {
              ...newEventWithoutNotif,
              start: fromDate(_start),
              end: fromDate(_end),
              id: doc.id,
              recurringEventId: doc.recurringEventId
            });
          }
        });
        batch.commit();
      }

      dispatch(slice.actions.updateEventSuccess(events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventToDelete, scope) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client, calendar } = cloneDeep(getState());
    const { currentClient } = client;
    const { events } = calendar;

    try {
      const { recurringEventId, start, notification } = eventToDelete;

      const collectionRef = db.collection('clients').doc(currentClient.id).collection('events');

      let parentRecurrEventRef = null;
      const isRecurrParentEvent = eventToDelete.recurringEventId === null && eventToDelete.recurrence !== 'no-repeat';

      if (isRecurrParentEvent) {
        parentRecurrEventRef = collectionRef.doc(eventToDelete.id);
      } else if (eventToDelete.recurringEventId !== null) {
        const _parentDoc = await collectionRef.doc(recurringEventId).get();
        if (_parentDoc.exists) {
          parentRecurrEventRef = collectionRef.doc(recurringEventId);
        } else {
          parentRecurrEventRef = null;
        }
      }

      const notifCollectionRef = db.collection('notifications');

      if (scope === 'only') {
        if (notification) {
          try {
            await notifCollectionRef
              .where('eventId', '==', eventToDelete.id)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const curNotification = doc.data();
                  db.doc(`notifications/${curNotification.id}`).delete();
                });
              });
          } catch (error) {
            console.log(error);
          }
        }
        await collectionRef.doc(eventToDelete.id).delete();
        const deleteEvent = events.filter((curEvent) => curEvent.id !== eventToDelete.id);
        dispatch(slice.actions.deleteEventSuccess(deleteEvent));
      }

      // query for set of events to delete
      if (scope === 'future' || scope === 'all') {
        const collectionGroupRef = db
          .collectionGroup('events')
          .where('recurringEventId', '==', recurringEventId !== null ? recurringEventId : eventToDelete.id);

        const batch = db.batch();
        const eventDocsToDelete = [];
        const pullIndexes = [];

        // get set of events to delete
        if (scope === 'future') {
          if (isRecurrParentEvent && parentRecurrEventRef) {
            await parentRecurrEventRef.get().then((doc) => eventDocsToDelete.push(doc.data()));
          }

          const eventGroup = await collectionGroupRef.orderBy('start').startAt(start).get();
          eventGroup.forEach((doc) => eventDocsToDelete.push(doc.data()));

          // TODO: update the recurring end date for remaining events
          // const eventGroupBefore = await collectionGroupRef.orderBy('start').endBefore(start).get();
          // console.log(eventGroupBefore);
          // if (eventGroupBefore.docs.length > 0) {
          //   const lastEvent = eventGroupBefore.docs[eventGroupBefore.docs.length - 1];
          //   const lastEventStart = lastEvent.data().start;
          //   eventGroupBefore.forEach((doc) => {
          //     const eventToUpdate = collectionRef.doc(doc.id);
          //     batch.update(eventToUpdate, {
          //       recurrenceEndDate: fromDate(lastEventStart)
          //     });
          //   });
          // }
        }

        if (scope === 'all') {
          if (parentRecurrEventRef) {
            await parentRecurrEventRef.get().then((doc) => eventDocsToDelete.push(doc.data()));
          }
          const eventGroup = await collectionGroupRef.get();
          eventGroup.forEach((doc) => {
            eventDocsToDelete.push(doc.data());
          });

          pullIndexes.push(getEventIndexById(events, recurringEventId));
        }

        // -------------------------------------------------------------------

        // get event ids of events with notifications
        const eventIds = [];
        eventDocsToDelete.forEach((doc) => {
          if (doc.notification) {
            eventIds.push(doc.id);
          }
        });

        // if there are notifications to delete, query for them, else delete events
        if (eventIds.length > 0) {
          const notificationsToDelete = []; // firestore where method 'in' query has a 10 value limit
          const eventIdsChunks = [];
          for (let i = 0; i < eventIds.length; i += 10) {
            eventIdsChunks.push(eventIds.slice(i, i + 10));
          }
          const promises = [];

          for (let i = 0; i < eventIdsChunks.length; i++) {
            eventIdsChunks[i].forEach(() => {
              promises.push(
                notifCollectionRef
                  .where('eventId', 'in', eventIdsChunks[i])
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      notificationsToDelete.push(doc.data());
                    });
                  })
              );
            });
          }
          Promise.all(promises)
            // .then is being used here because the nested for and forEach loops are completing before promises are resolved and notificationsToDelete is empty
            .then(() => {
              eventDocsToDelete.forEach((event) => {
                const eventIndex = getEventIndexById(events, event.id);
                pullIndexes.push(eventIndex);
                if (notificationsToDelete.length > 0) {
                  notificationsToDelete.forEach((notification) => {
                    if (notification.eventId === event.id) {
                      batch.delete(notifCollectionRef.doc(notification.id));
                    }
                  });
                }
                batch.delete(collectionRef.doc(event.id));
              });

              pullAt(events, pullIndexes);
              dispatch(slice.actions.deleteEventSuccess(events));
              batch.commit();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          eventDocsToDelete.forEach((event) => {
            const eventIndex = getEventIndexById(events, event.id);
            pullIndexes.push(eventIndex);
            batch.delete(collectionRef.doc(event.id));
          });

          pullAt(events, pullIndexes);

          // I may try to update the recurrence end date for the remaining events here
          // if (scope === 'future') {
          //   const lastEvent = events[events.length - 1];
          //   const newRecurrenceEndDate = fromDate(lastEvent.start);

          dispatch(slice.actions.deleteEventSuccess(events));
          batch.commit();
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async (dispatch) => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };
}

// CALENDARS
// ----------------------------------------------------------------------

export function getCalendars(clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await db.collection(`clients/${clientId}/calendars`).get();
      const calendars = [];
      const calendarList = [];
      response.forEach((calendar) => {
        calendars.push(calendar.data());
        if (calendar.data().name !== 'Unassigned') {
          calendarList.push(calendar.data());
        }
      });
      dispatch(slice.actions.getCalendarsSuccess({ calendars, calendarList }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCalendars(values, clientId, calendarId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const calendarRef = db.collection(`clients/${clientId}/calendars`).doc(calendarId);
      await calendarRef.set({ ...values }, { merge: true });

      const eventsCollectionRef = await db.collection(`clients/${clientId}/events`);
      const response = await eventsCollectionRef.get();
      const events = [];
      const ids = [];
      response.forEach((eventObj) => {
        const event = eventObj.data();
        if (event.calendars) {
          if (event.calendars[0] === `${calendarId}`) {
            events.push(event);
            ids.push(event.id);
          }
        }
      });
      const batch = db.batch();
      for (const event of events) {
        const eventRef = eventsCollectionRef.doc(event.id);
        batch.set(eventRef, { textColor: values.groupColor }, { merge: true });
      }
      batch.commit();
      dispatch(slice.actions.getUpdateCalendarSuccess({ ...values, eventIds: ids, id: calendarId }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createCalendar(values, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const { id } = values;
    try {
      const calendarRef = db.collection(`clients/${clientId}/calendars`).doc(id);
      const firebaseObject = {
        ...values,
        eventIds: []
      };
      await calendarRef.set(firebaseObject, { merge: true });

      dispatch(slice.actions.getCreateCalendarSuccess(firebaseObject));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCalendar(calendar, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const { id } = calendar;
    try {
      await db.doc(`clients/${clientId}/calendars/${id}`).delete();
      const eventsCollectionRef = await db.collection(`clients/${clientId}/events`);
      const response = await eventsCollectionRef.get();
      const events = [];
      const ids = [];
      response.forEach((eventObj) => {
        const event = eventObj.data();
        if (event.calendars) {
          if (event.calendars[0] === `${id}`) {
            events.push(event);
            ids.push(event.id);
          }
        }
      });
      const batch = db.batch();
      for (const event of events) {
        const eventRef = eventsCollectionRef.doc(event.id);
        batch.set(eventRef, { calendars: ['unassigned'] }, { merge: true });
        batch.set(eventRef, { textColor: '#e6e6e6' }, { merge: true });
      }
      batch.commit();
      dispatch(slice.actions.getDeleteCalendarSuccess({ eventIds: ids, calendarId: id }));
      dispatch(slice.actions.isSuccess('Calendar delete success'));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
