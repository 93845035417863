import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(utc);

/**
 *
 * @param {*} date
 * @returns
 *
 */
export function roundToNearestHalfHour(date) {
  const currentMinutes = date.getMinutes();
  if (currentMinutes < 30) {
    date.setMinutes(30);
  } else {
    date.setMinutes(60);
  }
  return date;
}

/**
 *
 * @param {*} date
 * @returns
 *
 */
export function roundToNextHour(date) {
  const currentHour = date.getHours();
  date.setHours(currentHour + 1);
  return date;
}

/**
 *
 * @param {*} date
 * @param {*} customFormat
 * @returns
 *
 */
export function formatDate(date, customFormat) {
  if (typeof date === 'number') {
    date = dayjs.unix(date);
  } else {
    date = dayjs(date);
  }
  let formatter = 'MMMM D, YYYY';
  if (customFormat) {
    formatter = customFormat;
  }
  return date.format(formatter);
}

/**
 *
 * @param {*} date
 * @returns
 *
 */
export function getUnix(date) {
  date = dayjs(date);
  return date.unix();
}

/**
 *
 * @param {*} timestamp
 * @returns formatted date from timestamp
 *
 */
export function formatTimestamp(timestamp, customFormat) {
  let formatting = 'YYYY-MM-DD HH:mm:ss';

  if (customFormat) {
    formatting = customFormat;
  }
  if (typeof timestamp !== 'number') {
    throw Error('MUST PASS UNIX TIMESTAMP');
  }
  const formatTimestamp = `${formatDate(timestamp, formatting)}`;
  return formatTimestamp;
}

/**
 *
 * @param {*} timestamp Unix timestamp
 * @param {*} timezone America/Phoenix
 */
export function convertTimestampToTimezone(timestamp, timezone, customFormat) {
  let formatter = 'YYYY-MM-DD HH:mm:ss';
  if (customFormat) {
    formatter = customFormat;
  }
  return dayjs(timestamp * 1000)
    .tz(timezone)
    .format(formatter);
}

/**
 *
 * @param {*} timestamp
 * @param {*} utcOffset
 * @returns
 */
export function getUtcOffset(timestamp, utcOffset) {
  // Checks if timestamp is unix and converts if not
  if (typeof timestamp !== 'number') {
    timestamp = dayjs(timestamp).unix();
  }
  const formatting = 'YYYY-MM-DD HH:mm:ss';

  const formattedDate = dayjs
    .utc(timestamp * 1000)
    .utcOffset(utcOffset)
    .format(formatting);

  return dayjs(formattedDate).unix();
}

export function formatTime(time, customFormat) {
  let formatter = 'h:mm A';
  if (customFormat) {
    formatter = customFormat;
  }
  time = time.split(':');
  const hour = time[0];
  const minute = time[1];
  const date = dayjs().hour(hour).minute(minute);

  return dayjs(date).format(formatter);
}

export function isDST(date) {
  const secondSundayMarch = new Date(date.getFullYear(), 2, 14 - date.getDay());
  const firstSundayNovember = new Date(date.getFullYear(), 10, 7 - date.getDay());
  return date > secondSundayMarch && date < firstSundayNovember;
}

export const timezoneAbbreviations = {
  'America/Puerto_Rico': { standard: 'AST', daylight: 'AST' }, // Does not observe DST
  'America/New_York': { standard: 'EST', daylight: 'EDT' },
  'America/Chicago': { standard: 'CST', daylight: 'CDT' },
  'America/Denver': { standard: 'MST', daylight: 'MDT' },
  'America/Phoenix': { standard: 'MST', daylight: 'MST' }, // Does not observe DST
  'America/Los_Angeles': { standard: 'PST', daylight: 'PDT' },
  'America/Anchorage': { standard: 'AKST', daylight: 'AKDT' },
  'America/Adak': { standard: 'HAST', daylight: 'HADT' },
  'Pacific/Honolulu': { standard: 'HST', daylight: 'HST' } // Does not observe DST
};

// manual dst display time adjustment so that hours are displayed at the same time regardless of DST, takes in date objects and timezone string, outputs formatted time strings
export function removeDSTOffset(start, end, timeZone) {
  let startTime = null;
  let endTime = null;

  // is today's date in daylight savings time?
  const todayInDST = isDST(new Date());
  // is the event in daylight savings time?
  const _startTime = new Date(start);
  const _endTime = new Date(end);

  const inStandardTZ =
    timeZone === 'America/Phoenix' || timeZone === 'America/Puerto_Rico' || timeZone === 'Pacific/Honolulu';

  if (inStandardTZ) {
    startTime = start ? dayjs(_startTime).format('h:mm a') : '';
    endTime = end ? dayjs(_endTime).format('h:mm a') : '';
    return { startTime, endTime };
  }

  // if we're currently observing daylight time and the event is not in standard time add an hour
  if (todayInDST && !isDST(_startTime) && !inStandardTZ) {
    startTime = start ? dayjs(_startTime).add(1, 'hour').format('h:mm a') : '';
    endTime = end ? dayjs(_endTime).add(1, 'hour').format('h:mm a') : '';
  }
  // if we're currently observing standard time and the event is in daylight time and not in standard time, subtract an hour
  else if (!todayInDST && isDST(_startTime) && !inStandardTZ) {
    startTime = start ? dayjs(_startTime).subtract(1, 'hour').format('h:mm a') : '';
    endTime = end ? dayjs(_endTime).subtract(1, 'hour').format('h:mm a') : '';
  } else {
    startTime = start ? dayjs(_startTime).format('h:mm a') : '';
    endTime = end ? dayjs(_endTime).format('h:mm a') : '';
  }
  return { startTime, endTime };
}

// eslint-disable-next-line
export function mapTimezoneOffset(timezone) {
  switch (timezone) {
    // possible daylight savings stuff
    case 'America/New_York':
      return 'EDT';
    case 'America/Chicago':
      return 'CDT';
    case 'America/Phoenix':
      return 'MST';
    case 'America/Los_Angeles':
      return 'PDT';
    default:
  }
}
